.brandsItem ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.brandsItem ul li a {
    display: flex;
    background: #f3f4f7;
    text-align: center;
    justify-content: center;
    height: 120px;
    padding: 20px;
    width: 160px;
}