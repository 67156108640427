.aboutCompanyText {
    margin-bottom: 40px;
    padding-bottom: 15px;
    border-bottom: 1px solid #efefef;
}

.aboutCompanyText h3 {
    font-size: 17px;
    color: #131011;
    text-transform: uppercase;
}

.aboutCompanyText p {
    color: #6e6e6e;
    font-size: 15px;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.facebook {
    background-color: #3c579e;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.twitter {
    background-color: #1cb8ec;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.instagram {
    background-color: #ec0a68;
}

.footer-section.footer-bg .single-footer-widget .footer-social a.whatsapp {
    background-color: #1bd741;
}

@media (max-width: 500px) {

    .aboutCompanyText,
    .single-footer-widget {
        text-align: center;
    }

    .footer-info-contact {
        text-align: left;
    }

    .copyright-area .copyright-area-content {
        text-align: center;
    }

    .copyright-area .copyright-area-content ul {
        text-align: center;
    }

    .single-footer-widget .footer-info-contact {
        justify-content: center;
        text-align: center;
        display: flex;
    }

    .single-footer-widget .footer-info-contact .topIcon svg {
        display: none;
    }
}